@import 'assets/styles/mixins.scss';

.hijackBar {
  position: fixed;
  right: 0px;
  top: 60px;
  bottom: auto;
  z-index: 1010;
  background: $primary;
  color: $white;
  box-shadow: 0 0px 35px -5px rgba(0, 0, 0, 0.15);
  padding: 0.5rem 1.2rem;
}
